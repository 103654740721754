import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table
} from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../utils/API";
import BulkResult from "./detailsview/BulkResult";
import CSVDownlaod from "../../components/CsvDownload";
import DateRange from "../../components/formfields/DateRange";
import Datetime from "../../components/fields/Datetime";
import LeadID from "../../components/fields/LeadID";
import LeadSourceSelect from "../../components/formfields/LeadSourceSelect";
import LeadStatus from "../../components/status/LeadStatus";
import LeadStatusSelect from "../../components/formfields/LeadStatusSelect";
import ListStat from "../../components/ListStat";
import Loader from "../../components/Loader";
import MoneyPub from "../../components/fields/MoneyPub";
import MoneySystem from "../../components/fields/MoneySystem";
import MoneyTotal from "../../components/fields/MoneyTotal";
import Pagination from "../../components/Pagination";
import PerPageSelect from "../../components/formfields/PerPageSelect";
import ProcessTime from "../../components/fields/ProcessTime";
import Product from "../../components/fields/Product";
import ProductMulti from "../../components/formfields/ProductMulti";
import Publisher from "../../components/fields/Publisher";
import PublisherSelect from "../../components/formfields/PublisherSelect";
import RCID from "../../components/fields/RCID";
import RCIDSelect from "../../components/formfields/RCIDSelect";
import Select from "react-select";
import Source from "../../components/fields/Source";
import SubID from "../../components/fields/SubID";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../utils/helpers/filterHelper";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import SortDirection from "../../components/SortDirection";
import ProgressBar from "react-bootstrap/ProgressBar";
import usePermissions from "../../hooks/usePermissions";

const Details = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");
  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
  };
  const viewOptionList = [
    { value: "exceptions", label: "Status reasons" },
    { value: "lenders", label: "Lenders" },
  ];

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const mappedOptionList = filtersParsed?.viewOption?.map((item) => {
    const matchedFilter = viewOptionList.find(
      (option) => option.value === item
    );
    return matchedFilter || item;
  });

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [leads, setLeads] = useState(null);
  const [leadsAdditional, setLeadsAdditional] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [viewOption, setViewOption] = useState(mappedOptionList || []);

  const [reMatchMode, setReMatchMode] = useState(false);
  const [matchMode, setMatchMode] = useState(false);
  const [failMode, setFailMode] = useState(false);

  const [leadsToRematch, setLeadsToRematch] = useState([]);
  const [leadsToMatch, setLeadsToMatch] = useState([]);
  const [leadsToFail, setLeadsToFail] = useState([]);

  const [checkboxAllRematch, setCheckboxAllRematch] = useState(false);
  const [checkboxAllMatched, setCheckboxAllMatched] = useState(false);
  const [checkboxAllFailed, setCheckboxAllFailed] = useState(false);

  const [canBeRematch, setCanBeRematch] = useState([]);
  const [canBeMatch, setCanBeMatch] = useState([]);
  const [canBeFail, setCanBeFail] = useState([]);

  const maxReMatch = 10;

  const [batchProcess, setBatchProcess] = useState(false);
  const [batchResultModal, setBatchResultModal] = useState(false);

  const checkAllRematch = (e) => {
    if (e.target.checked) {
      setCheckboxAllRematch(true);
      setLeadsToRematch(canBeRematch.slice(0, maxReMatch));
    } else {
      setCheckboxAllRematch(false);
      setLeadsToRematch([]);
    }
  };

  const checkAllMatched = (e) => {
    if (e.target.checked) {
      setCheckboxAllMatched(true);
      setLeadsToMatch(canBeMatch);
    } else {
      setCheckboxAllMatched(false);
      setLeadsToMatch([]);
    }
  };

  const checkAllFailed = (e) => {
    if (e.target.checked) {
      setCheckboxAllFailed(true);
      setLeadsToFail(canBeFail);
    } else {
      setCheckboxAllFailed(false);
      setLeadsToFail([]);
    }
  };

  const handleRematchClick = (e) => {
    const { id, checked } = e.target;
    if (checked && leadsToRematch && leadsToRematch.length >= maxReMatch) {
      toastr.error(
        "Maximum",
        "You can not rematch more than " + maxReMatch + " leads at once."
      );
    } else {
      setLeadsToRematch([...leadsToRematch, parseInt(id)]);
      setCheckboxAllRematch(false);
      if (!checked) {
        setLeadsToRematch(leadsToRematch.filter((item) => item !== parseInt(id)));
      }
    }
  };

  const handleMatchClick = (e) => {
    const { id, checked } = e.target;
    setLeadsToMatch([...leadsToMatch, parseInt(id)]);
    setCheckboxAllMatched(false);
    if (!checked) {
      setLeadsToMatch(leadsToMatch.filter((item) => item !== parseInt(id)));
    }
  };

  const handleFailClick = (e) => {
    const { id, checked } = e.target;
    setLeadsToFail([...leadsToFail, parseInt(id)]);
    setCheckboxAllFailed(false);
    if (!checked) {
      setLeadsToFail(leadsToFail.filter((item) => item !== parseInt(id)));
    }
  };

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  useEffect(() => {
    loadData();
  }, [filtration, viewOption]);

  const loadData = (hideLoading, includeLeadsIds) => {
    if (!hideLoading) setLoading(true);

    setLeadsAdditional(null);

    API.post("/admin/lead/detailreport", {
      ...filtration,
      leadIds: includeLeadsIds,
      viewOption:
        viewOption && viewOption.length
          ? viewOption.map((elem) => elem.value)
          : [],
    }).then((response) => {
      setLeads(response.data.data.data);
      setData(response.data.data);

      let canBeRematchTemp = [];
      let canBeMatchTemp = [];
      let canBeFailedTemp = [];

      response.data.data.data.map((lead) => {
        if (lead.status !== "matched") {
          canBeRematchTemp.push(lead.id);
        }
        if (lead.status === "unmatched") {
          canBeMatchTemp.push(lead.id);
        }
        if (lead.status === "flagged") {
          canBeFailedTemp.push(lead.id);
        }
      });

      setCanBeRematch(canBeRematchTemp);
      setCanBeMatch(canBeMatchTemp);
      setCanBeFail(canBeFailedTemp);

      setLeadsToRematch([]);
      setLeadsToMatch([]);
      setLeadsToFail([]);

      setCheckboxAllRematch(false);
      setCheckboxAllMatched(false);
      setCheckboxAllFailed(false);

      setBatchProcess(false);

      if (response.data.data.additionalData) {
        setLeadsAdditional(response.data.data.additionalData);
      }

      if (!hideLoading) setLoading(false);
    });
  };

  const viewEnabled = (name) => {
    if (!viewOption) {
      return false;
    }
    let i,
      n = viewOption.length;
    for (i = 0; i < n; i++) {
      if (viewOption[i].value === name) {
        return true;
      }
    }
    return false;
  };

  const toggleReMatchMode = () => {
    if (!reMatchMode) {
      setReMatchMode(true);
      setMatchMode(false);
      setFailMode(false);
      setViewOption([{ value: "lenders", label: "Lenders" }]);

      setLeadsToRematch([]);
      setLeadsToMatch([]);
      setLeadsToFail([]);

      setCheckboxAllRematch(false);
      setCheckboxAllMatched(false);
      setCheckboxAllFailed(false);

      setLeadsAdditional([]);
    } else {
      setReMatchMode(false);
      setViewOption([]);
    }
  };

  const toggleMatchMode = () => {
    if (!matchMode) {
      setMatchMode(true);
      setReMatchMode(false);
      setFailMode(false);

      setLeadsToRematch([]);
      setLeadsToMatch([]);
      setLeadsToFail([]);

      setCheckboxAllRematch(false);
      setCheckboxAllMatched(false);
      setCheckboxAllFailed(false);

      setLeadsAdditional([]);
    } else {
      setMatchMode(false);
      setViewOption([]);
    }
  };

  const toggleFailMode = () => {
    if (!failMode) {
      setFailMode(true);
      setMatchMode(false);
      setReMatchMode(false);

      setLeadsToRematch([]);
      setLeadsToMatch([]);
      setLeadsToFail([]);

      setCheckboxAllRematch(false);
      setCheckboxAllMatched(false);
      setCheckboxAllFailed(false);

      setLeadsAdditional([]);
    } else {
      setFailMode(false);
      setViewOption([]);
    }
  };

  const [percentageBatch, setPercentageBatch] = useState(0);

  async function startRematch() {
    if (leadsToRematch && leadsToRematch.length > 0) {
      let includeLeadIds = leadsToRematch;
      await setBatchProcess(true);
      await setBatchResultModal(true);

      let total = leadsToRematch.length;
      let processed = 0;

      await setPercentageBatch(0);

      await Promise.all(
        leadsToRematch.map(async (leadsToRematchItem) => {
          await API.post(`/admin/lead/manualpostingall/${leadsToRematchItem}`);
          processed = processed + 1;
          setPercentageBatch(Math.round(100*processed/total));
        })
      );

      await setBatchProcess(false);

      await setLeadsToRematch([]);
      await loadData(false, includeLeadIds);
    }
  }

  async function startMatch() {
    if (leadsToMatch && leadsToMatch.length > 0) {
      let includeLeadIds = leadsToMatch;
      await setBatchProcess(true);
      await setBatchResultModal(true);

      let total = leadsToMatch.length;
      let processed = 0;

      await setPercentageBatch(0);
      await Promise.all(
        leadsToMatch.map(async (leadsToMatchItem) => {
          await API.post(`/admin/lead/matchednobuyer/${leadsToMatchItem}`);
          processed = processed + 1;
          setPercentageBatch(Math.round(100*processed/total));
        })
      );

      await setBatchProcess(false);

      await setLeadsToMatch([]);
      await loadData(false, includeLeadIds);
    }
  }

  async function startFail() {
    if (leadsToFail && leadsToFail.length > 0) {
      let includeLeadIds = leadsToFail;
      await setBatchProcess(true);
      await setBatchResultModal(true);

      let total = leadsToFail.length;
      let processed = 0;

      await setPercentageBatch(0);
      await Promise.all(
        leadsToFail.map(async (leadsToFailItem) => {
          await API.post(`/admin/lead/faillead/${leadsToFailItem}`);
          processed = processed + 1;
          setPercentageBatch(Math.round(100*processed/total));
        })
      );

      await setBatchProcess(false);

      await setLeadsToFail([]);
      await loadData(false, includeLeadIds);
    }
  }

  const updateViewOption = (value) => {
    let i,
      values = [];
    for (i = 0; i < value?.length; i++) {
      values.push(value[i].value);
    }
    setFiltration({ ...filtration, viewOption: values });
    setViewOption(value);

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({
        ...filtration,
        viewOption: values,
      }).toString(),
    });
  };

  const canFailLead = usePermissions('lead_faillead');
  const canRematchLead = usePermissions('lead_rematchlead');
  const canMatchNoBuyer = usePermissions('lead_setmatchnobuyer');

  const canLeadDownload = usePermissions('download_fullleadinfo');

  return (
      <Container fluid className="p-0">
        <div className="row">
          <div className="col text-left">
            <h1 className="h3 mb-3">Leads details</h1>
          </div>
          {(canRematchLead && canBeRematch.length > 0) && (
              <div className="col-auto text-right">
                <button
                    className={`btn ${
                        reMatchMode ? `btn-success` : `btn-outline-success`
                    }`}
                    onClick={() => toggleReMatchMode()}
                >
                  Re-Match mode
                </button>
              </div>
          )}
          {(canMatchNoBuyer && canBeMatch.length > 0) && (
              <div className="col-auto text-right">
                <button
                    className={`btn ${
                        matchMode ? `btn-primary` : `btn-outline-primary`
                    }`}
                    onClick={() => toggleMatchMode()}
                >
                  Match no buyer mode
                </button>
              </div>
          )}
          {(canFailLead && canBeFail.length > 0) && (
              <div className="col-auto text-right">
                <button
                    className={`btn ${
                        failMode ? `btn-danger` : `btn-outline-danger`
                    }`}
                    onClick={() => toggleFailMode()}
                >
                  Fail mode
                </button>
              </div>
          )}
          <div className="col-auto">
            {canLeadDownload &&
            <CSVDownlaod
                url="/admin/lead/detailreportfulldatacsv"
                filename="LeadDetailsReport"
                filtration={filtration}
            />
            }
          </div>
        </div>
        <Row form>
          <Col md={3}>
            <DateRange
                onChange={changeData}
                name={"date"}
                value={filtration?.date}
            />
          </Col>
          <Col md={3}>
            <ProductMulti
                name={"product"}
                onChange={changeData}
                value={filtration?.product}
            />
          </Col>
          <Col md={3}>
            <PublisherSelect
                name={"publisher"}
                onChange={changeData}
                value={filtration?.publisher}
            />
          </Col>
          <Col md={3}>
            <RCIDSelect
                name={"rcid"}
                onChange={changeData}
                product={filtration?.product}
                publisher={filtration?.publisher}
                value={filtration?.rcid}
            />
          </Col>
        </Row>
        <Row form className="mt-1">
          <Col md={3}>
            <LeadStatusSelect
                name={"status"}
                onChange={changeData}
                value={filtration?.status}
            />
          </Col>
          <Col md={3}>
            <LeadSourceSelect
                name={"source"}
                onChange={changeData}
                value={filtration?.source}
            />
          </Col>
          <Col md={3}>
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={viewOptionList}
                value={viewOption}
                onChange={(value) => {
                  updateViewOption(value);
                }}
                isMulti
                placeholder="View options"
            />
          </Col>
          <Col md={3}>
            <PerPageSelect
                onChange={changeData}
                name={"perPage"}
                value={filtration.perPage}
            />
          </Col>
        </Row>
        {loading && <Loader/>}
        {leadsToRematch && leadsToRematch.length > 0 && !loading && (
            <div
                className="mb-2 mt-2"
                style={{
                  position: "sticky",
                  top: "10px",
                  right: "10px",
                  zIndex: 1000,
                }}
            >
              <button className="btn btn-success" onClick={() => startRematch()}>
                Rematch <strong>{leadsToRematch.length}</strong> leads
              </button>
            </div>
        )}
        {leadsToMatch && leadsToMatch.length > 0 && !loading && (
            <div
                className="mb-2 mt-2"
                style={{
                  position: "sticky",
                  top: "10px",
                  right: "10px",
                  zIndex: 1000,
                }}
            >
              <button className="btn btn-primary" onClick={() => startMatch()}>
                Match no buyer <strong>{leadsToMatch.length}</strong> leads
              </button>
            </div>
        )}
        {leadsToFail && leadsToFail.length > 0 && !loading && (
            <div
                className="mb-2 mt-2"
                style={{
                  position: "sticky",
                  top: "10px",
                  right: "10px",
                  zIndex: 1000,
                }}
            >
              <button className="btn btn-danger" onClick={() => startFail()}>
                Fail <strong>{leadsToFail.length}</strong> leads
              </button>
            </div>
        )}

        {!loading && leads?.length > 0 ? (
            <>
              {leads?.length > 0 && (
                  <Card className="mt-2">
                    <div className="table-responsive">
                      <ListStat data={data}/>
                      {reMatchMode && (
                          <div className="m-1 p-1 badge badge-success">
                            Re-Match mode
                          </div>
                      )}
                      {matchMode && (
                          <div className="m-1 p-1 badge badge-primary">
                            Match no buyer mode
                          </div>
                      )}
                      {failMode && (
                          <div className="m-1 p-1 badge badge-danger">Fail mode</div>
                      )}
                      <Table>
                        <thead>
                        <tr>
                          {reMatchMode && (
                              <th>
                                <input
                                    type="checkbox"
                                    onChange={(e) => checkAllRematch(e)}
                                    checked={checkboxAllRematch}
                                />
                              </th>
                          )}
                          {matchMode && (
                              <th>
                                <input
                                    type="checkbox"
                                    onChange={(e) => checkAllMatched(e)}
                                    checked={checkboxAllMatched}
                                />
                              </th>
                          )}
                          {failMode && (
                              <th>
                                <input
                                    type="checkbox"
                                    onChange={(e) => checkAllFailed(e)}
                                    checked={checkboxAllFailed}
                                />
                              </th>
                          )}
                          <th>Id<SortDirection fieldName={'id'} filters={filtration} changeFilter={changeData}/></th>
                          <th>Date<SortDirection fieldName={'dateadd'} filters={filtration} changeFilter={changeData}/>
                          </th>
                          <th>PT<SortDirection fieldName={'pt'} filters={filtration} changeFilter={changeData}/></th>
                          <th>Status<SortDirection fieldName={'status'} filters={filtration} changeFilter={changeData}/>
                          </th>
                          {viewEnabled("exceptions") && <th>Reason</th>}
                          {viewEnabled("lenders") && <th>Lenders</th>}
                          <th>Product<SortDirection fieldName={'product'} filters={filtration}
                                                    changeFilter={changeData}/></th>
                          <th>Publisher<SortDirection fieldName={'publisher'} filters={filtration}
                                                      changeFilter={changeData}/></th>
                          <th>Sub ID<SortDirection fieldName={'subid'} filters={filtration} changeFilter={changeData}/>
                          </th>
                          <th>RCID<SortDirection fieldName={'rcid'} filters={filtration} changeFilter={changeData}/>
                          </th>
                          <th>Source<SortDirection fieldName={'source'} filters={filtration} changeFilter={changeData}/>
                          </th>
                          <th>Total<SortDirection fieldName={'earntotal'} filters={filtration}
                                                  changeFilter={changeData}/></th>
                          <th>Pub<SortDirection fieldName={'earnpub'} filters={filtration} changeFilter={changeData}/>
                          </th>
                          <th>System<SortDirection fieldName={'earnsystem'} filters={filtration}
                                                   changeFilter={changeData}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {leads.map((lead, index) => {
                          return (
                              <tr key={index}>
                                {reMatchMode && (
                                    <td>
                                      {canBeRematch.includes(lead.id) && (
                                          <input
                                              type="checkbox"
                                              onChange={handleRematchClick}
                                              id={lead.id}
                                              checked={leadsToRematch.includes(lead.id)}
                                          />
                                      )}
                                    </td>
                                )}
                                {matchMode && (
                                    <td>
                                      {canBeMatch.includes(lead.id) && (
                                          <input
                                              type="checkbox"
                                              onChange={handleMatchClick}
                                              id={lead.id}
                                              checked={leadsToMatch.includes(lead.id)}
                                          />
                                      )}
                                    </td>
                                )}
                                {failMode && (
                                    <td>
                                      {canBeFail.includes(lead.id) && (
                                          <input
                                              type="checkbox"
                                              onChange={handleFailClick}
                                              id={lead.id}
                                              checked={leadsToFail.includes(lead.id)}
                                          />
                                      )}
                                    </td>
                                )}
                                <td>
                                  <LeadID id={lead.id} reloadList={loadData}/>
                                </td>
                                <td>
                                  <Datetime dateTime={lead.dateadd}/>
                                </td>
                                <td>
                                  <ProcessTime
                                      dateAdd={lead.dateadd}
                                      dateUpdate={lead.dateupdate}
                                      lessOneSeconds={""}
                                  />
                                </td>
                                <td>
                                  <LeadStatus status={lead.status}/>
                                </td>
                                {viewEnabled("exceptions") && (
                                    <td>
                                      {lead?.exceptions?.map((exception, excKey) => {
                                        return (
                                            <div key={excKey}>
                                              <nobr>{exception}</nobr>
                                            </div>
                                        );
                                      })}
                                    </td>
                                )}
                                {viewEnabled("lenders") && (
                                    <td>
                                      {lead?.lenders?.map((lender, lenKey) => {
                                        let color,
                                            display = true;
                                        if (lender.status === "matched") {
                                          color = "text-success";
                                        } else if (lender.status === "unmatched") {
                                          color = "text-black-50";
                                        } else {
                                          color = "text-danger";
                                        }
                                        return (
                                            <div key={lenKey} className={color}>
                                              <nobr>
                                                {lender.lenderorder}::{lender.lender}
                                              </nobr>
                                            </div>
                                        );
                                      })}
                                    </td>
                                )}
                                <td className="">
                                  <Product product={lead.product}/>
                                </td>
                                <td>
                                  <Publisher publisher={lead.publisher}/>
                                </td>
                                <td>
                                  <SubID subid={lead.subid}/>{" "}
                                </td>
                                <td>
                                  <RCID id={lead.rcid}/>
                                </td>
                                <td>
                                  <Source source={lead.leadsource}/>
                                </td>
                                <td>
                                  <MoneyTotal
                                      money={lead.earntotal}
                                      moneyOriginal={lead.earntotaloriginal}
                                  />
                                </td>
                                <td>
                                  <MoneyPub
                                      money={lead.earnpub}
                                      moneyOriginal={lead.earnpuboriginal}
                                  />
                                </td>
                                <td>
                                  <MoneySystem
                                      money={lead.earntotal - lead.earnpub}
                                  />
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </Table>
                    </div>
                  </Card>
              )}
            </>
        ) : (
            !loading && (
                <Card className="mt-2">
                  <CardBody className="text-center">
                    <h5>Data not found</h5>
                  </CardBody>
                </Card>
            )
        )}
        <>
          {data?.page > 0 && data?.pages > 0 && (
              <Pagination
                  onChange={changeData}
                  name={"page"}
                  page={data.page}
                  totalPages={data.pages}
                  loading={loading}
              />
          )}
        </>

        <React.Fragment>
          <Modal
              isOpen={batchResultModal}
              toggle={() => setBatchResultModal(!batchResultModal)}
              size="lg"
              style={{width: "90%", minHeight: "500px", maxWidth: "90%"}}
          >
            <ModalHeader toggle={() => setBatchResultModal(!batchResultModal)}>
              <strong>
                {reMatchMode && (
                    <span>Re-Match Process</span>
                )}
                {matchMode && (
                    <span>Match no buyer Process</span>
                )}
                {failMode && (
                    <span>Fail Process</span>
                )}
              </strong>
            </ModalHeader>
            <ModalBody>
              {batchProcess &&
                  <div className="justify-content-center align-self-center w-100 text-center">
                    {percentageBatch === 0 &&
                        <div className="text-danger"><h3 className="text-danger">Please don't refresh a page</h3></div>
                    }
                    {percentageBatch > 0 ?
                        <ProgressBar
                            variant={'primary'}
                            min={0}
                            max={100}
                            animated={true}
                            now={percentageBatch} label={`${percentageBatch}%`}/>
                        :
                        <><span style={{fontSize: '12px'}}>Starting up  <Spinner color="primary" size={"sm"}/></span></>
                    }
                  </div>
              }
              {!batchProcess && leadsAdditional && leadsAdditional?.length > 0 && (
                  <Card className="mt-2">
                    <div className="table-responsive">
                      <div className="mt-1 mb-1">
                        {reMatchMode && (
                            <span>{leadsAdditional?.length} leads have been re-matched </span>
                        )}
                        {matchMode && (
                            <span>{leadsAdditional?.length} leads have been matched no buyer</span>
                        )}
                        {failMode && (
                            <span>{leadsAdditional?.length} leads have been failed</span>
                        )}
                      </div>
                      <BulkResult
                          viewEnabled={viewEnabled}
                          leads={leadsAdditional}
                          loadData={loadData}
                      />
                    </div>
                  </Card>
              )}
            </ModalBody>
          </Modal>
        </React.Fragment>

      </Container>
  );
};

export default Details;
