import React, {useEffect, useState} from "react";
import API from "../../../utils/API";
import {
    Button,
    Container, FormGroup, Label,
} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import LeadID from "../../../components/fields/LeadID";
import PingID from "../../../components/fields/PingId";

export const PingPost = () => {
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [editConfig, setEditConfig] = useState(null);
    const [leadBody, setLeadBody] = useState({});

    const [response, setResponse] = useState(null);
    const [responsePostMatch, setResponsePostMatch] = useState(null);

    const [errors, setErrors] = useState(null);

    const [oneToOne, setOneToOne] = useState(null);
    const [oneToOneSelected, setOneToOneSelected] = useState([]);
    const [pingId, setPingId] = useState(null);
    const [leadId, setLeadId] = useState(null);

    const [rcids, setRcids] = useState(null);
    const [rcid, setRcid] = useState(null);

    const [sendFieldsSelected, setSendFieldsSelected] = useState([]);

    const handleChange = (e) => {
        setLeadBody({ ...leadBody, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        API.get('/system/products').then((response) => {
            setProducts(response.data.data);
        });
    }, []);

    useEffect(() => {
        setEditConfig(null);
        setOneToOne(null);
        setPingId(null);
        setLeadId(null);
        setRcid(null);
        setRcids(null);
        setSendFieldsSelected([]);

        if (product) {
            API.get(`/testautomation/productfields/${product}/pingpost`).then((response) => {
                const leadDataTemp = {};

                const fieldList = [];

                response.data.data.map((field) => {
                    leadDataTemp[field.name] = field.value;
                    fieldList.push(field.name)
                });

                setSendFieldsSelected(fieldList);
                setLeadBody(leadDataTemp);
                setEditConfig(response.data.data);
            });

            API.get(`/testautomation/rcids/${product}`).then((response) => {
                setRcids(response.data.data);
            });
        }
    }, [product]);

    const submit = () => {

        const leadBodySend = {};

        Object.keys(leadBody).forEach(key => {
            if (sendFieldsSelected.includes(key)) {
                leadBodySend[key] = leadBody[key];
            }
        });

        console.log(leadBodySend);

        API.post(`/lead/pingpost/ping`,leadBodySend).then((response) => {
            setProduct(null);
            setEditConfig(null);
            setResponse(response.data);

            setOneToOneSelected([]);
            setPingId(response.data.ping_id);
            setOneToOne(response.data.oneToOne);
            setResponsePostMatch(null);
        });
    }

    const handleSelect = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        setOneToOneSelected(
            checked
                ? [...oneToOneSelected, value]
                : oneToOneSelected.filter((item) => item !== value)
        );
    };

    const handleSelectFields = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        setSendFieldsSelected(
            checked
                ? [...sendFieldsSelected, value]
                : sendFieldsSelected.filter((item) => item !== value)
        );
    };

    const submitPostMatch = () => {
        const postSend = leadBody;
        postSend.ping_id = pingId;
        postSend.brand_consent = oneToOneSelected;

        API.post(`/lead/pingpost/post`,postSend).then((response) => {
            setProduct(null);
            setLeadBody(null);
            setEditConfig(null);
            setResponsePostMatch(response.data);
            setLeadId(response.data.lead_id);
        });
    }

    const selectRcid = (rcidItem) => {
        setRcid(rcidItem);
        const tempLeadBody = leadBody;
        tempLeadBody.publisher_id = rcidItem.publisherId;
        tempLeadBody.source = "pingpost";
        tempLeadBody.rcid = rcidItem.id;
        tempLeadBody.apikey = rcidItem.apiKey;

        setLeadBody(tempLeadBody);
    }

    return <Container fluid className="p-0">
        <h1 className="h3 mb-3">QA Ping Post Lead Generator</h1>
        {products &&
            <div className="mt-3">
                <h5>Select product</h5>
                <div className="row">
                    {products.map((productItem, index) => {
                        return <div className="col" key={index}>
                            <button disabled={productItem.classkey === product} className={`btn ${productItem.classkey === product ? 'btn-secondary' : 'btn-primary'} w-100`} onClick={()=>setProduct(productItem.classkey)}>{productItem.title}</button>
                        </div>
                    })}
                </div>
            </div>
        }
        {(rcids && rcids.length > 0) &&
            <div className="mt-3">
                <h5>Select rcid</h5>
                <div className="row mt-2">
                    {rcids.map((rcidItem, index) => {
                        return <div className="col" key={index}>
                            <button disabled={rcidItem.id === rcid?.id}
                                    className={`btn ${rcidItem.id === rcid?.id ? 'btn-secondary' : 'btn-primary'} w-100`}
                                    onClick={() => selectRcid(rcidItem)}>{rcidItem.id},
                                publisher: {rcidItem.publisherName}</button>
                        </div>
                    })}
                </div>
            </div>
        }
        {(!product && response) &&
            <>
                {pingId &&
                    <div className="m-3">Ping <PingID id={pingId}/></div>
                }
                <div className="m-3">
                    <h3>Response</h3>
                    <pre><code>{JSON.stringify(response)}</code></pre>
                </div>
                {(pingId && oneToOne && oneToOne.length) &&
                    <>
                        <div className="m-3">
                            <h3>Select One To One</h3>
                            {oneToOne.map((item,key) => {
                                return <div className="m-1" key={key}>
                                    <input type="checkbox" value={item.name} onClick={handleSelect}/> {item.name}
                                </div>
                            })}
                            {!responsePostMatch &&
                            <Button color="primary" onClick={submitPostMatch}>Send</Button>
                            }
                        </div>
                        {leadId &&
                            <div className="m-3">Lead <LeadID id={leadId}/></div>
                        }
                        {responsePostMatch &&
                            <div className="m-3">
                                <h3>Response</h3>
                                <pre><code>{JSON.stringify(responsePostMatch)}</code></pre>
                            </div>
                        }
                    </>
                }
            </>
        }
        {(rcid && editConfig) &&
            <div className={'mt-3'}>
                <AvForm>
                    <div className="text-center">
                        <Button color="primary" onClick={submit}>Send</Button>
                    </div>
                    {editConfig.map((field, index) => {
                        if (field.type === "string") {
                            return (
                                <FormGroup key={index}>
                                    <input type="checkbox" className="mr-2" value={field.name} onClick={handleSelectFields} checked={sendFieldsSelected.includes(field.name)}/>
                                    <Label>{field.name}</Label>
                                    <AvInput
                                        className={errors && errors[field.name] && "border-danger"}
                                        onChange={handleChange}
                                        required
                                        type="text"
                                        name={field.name}
                                        value={leadBody[field.name]}
                                        placeholder={"Enter " + field.name}
                                    />
                                    {errors && errors[field.name] && (
                                        <div className="text-danger font-size-sm">
                                            {errors[field.name]}
                                        </div>
                                    )}
                                </FormGroup>
                            );
                        } else if (field.type === "options") {
                            return (
                                <FormGroup key={index}>
                                    <input type="checkbox" className="mr-2" value={field.name}
                                           onClick={handleSelectFields}
                                           checked={sendFieldsSelected.includes(field.name)}/>
                                    <Label>{field.name}</Label>
                                    <AvField
                                        className={errors && errors[field.name] && "border-danger"}
                                        type="select"
                                        name={field.name}
                                        value={leadBody[field.name]}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select...</option>
                                        {field.options.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </AvField>
                                    {errors && errors[field.name] && (
                                        <div className="text-danger font-size-sm">
                                            {errors[field.name]}
                                        </div>
                                    )}
                                </FormGroup>
                            );
                        }
                    })}
                </AvForm>
            </div>
        }
    </Container>
}
